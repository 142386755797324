:root {
  --activePrimary: #ffffff;
  --primary: #180e03;
  --accent: #ff7b00;
  --primary-highlight: #f28500;
  --green: #2cf21e;
  --red: #ff2049;
  --yellow: #ffcf20;
  --dark-background: #0d1116;
  --dark-dark: #161b22;
  --dark-dropdown-background: #1b1b1b;
  --dark-mid: #31363c;
  --dark-more-mid: #25292e;
  --dark-mid-highlight: #414141;
  --dark-button-filter: 180%;
  --dark-light: #72777f;
  --dark-border-color: var(--activePrimary);
  --dark-border: 2px solid var(--border-color);
  --dark-less-white: #d2d1d1;
  --dark-white: #f3f3f3;
  --dark-fancy-border: #3e444cae;
  --dark-shadow: rgba(255, 255, 255, 0.5);
  --dark-overlay: #00000065;
  --dark-grid-color: #55555565;
  --dark-filter1: 0.95;
  --dark-filter2: 0.75;
  --dark-filter3: 1.3;
  --dark-filter4: 0.8;
  --dark-filter5: 1.9;
  --dark-filter6: 0.5;

  --light-background: #faf8f5;
  --light-dropdown-background: #f3f3f3;
  --light-dark: #fefefe;
  --light-mid: #f0efef;
  --light-more-mid: #f5f5f5;
  --light-mid-highlight: #ece9e6;
  --light-button-filter: 90%;
  --light-light: #f4f4f9;
  --light-border-color: var(--activePrimary);
  --light-border: 2px solid var(--border-color);
  --light-less-white: #929292;
  --light-white: #616060;
  --light-fancy-border: rgba(96, 96, 96, 0.143);
  --light-shadow: rgba(110, 110, 110, 0.5);
  --light-overlay: #79797965;
  --light-grid-color: #d6d6d665;
  --light-filter1: 1.05;
  --light-filter2: 1.35;
  --light-filter3: 1.02;
  --light-filter4: 1;
  --light-filter5: 0.98;
  --light-filter6: 1.3;

  --background: var(--dark-background);
  --dropdown-background: var(--dark-dropdown-background);
  --dark: var(--dark-dark);
  --mid: var(--dark-mid);
  --more-mid: var(--dark-more-mid);
  --mid-highlight: var(--dark-mid-highlight);
  --button-filter: var(--dark-button-filter);
  --light: var(--dark-light);
  --border-color: var(--dark-border-color);
  --border: var(--dark-border);
  --less-white: var(--dark-less-white);
  --white: var(--dark-white);
  --fancy-border: var(--dark-fancy-border);
  --shadow: var(--dark-shadow);
  --overlay: var(--dark-overlay);
  --grid-color: var(--dark-grid-color);
  --filter1: var(--dark-filter1);
  --filter2: var(--dark-filter2);
  --filter3: var(--dark-filter3);
  --filter4: var(--dark-filter4);
  --filter5: var(--dark-filter5);
  --filter6: var(--dark-filter6);

  --widget-size: 310px;
  background-color: var(--background);
  transition: background-color 0.3s ease-in-out;

  --scrollbar-thumb-color: var(--primary);
  --scrollbar-track-color: var(--dark);
  --scrollbar-width-webkit: 12px;
  --scrollbar-width-firefox: thin;
}

* {
  scrollbar-width: var(--scrollbar-width-firefox);
  scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-track-color);
}
button {
  font-size: 13px;
}

*::-webkit-scrollbar {
  width: var(--scrollbar-width-webkit);
}
*::-webkit-scrollbar-track {
  background: var(--scrollbar-track-color);
}
*::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-color);
  border-radius: 6px;
  border: 3px solid var(--scrollbar-track-color);
}

*::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-thumb-hover-color);
}

h1 {
  margin-top: 2.3rem;
}
.userfront-input {
  background-color: var(--mid) !important;
}
.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.btn {
  border: none;
  outline: none;
  cursor: pointer !important;
  padding: 8px 16px;
  border-radius: 5px;
  color: var(--white);
}

.btn:focus {
  outline: none;
}

.btn-primary {
  background-color: var(--primary);
}

.btn-primary:hover {
  background-color: var(--primary-highlight);
}

.btn-secondary {
  background-color: var(--mid);
  border: var(--border);
}

.btn-secondary:hover {
  background-color: var(--mid-highlight);
}

.home {
  margin-top: 50px;
}

.home .logo {
  height: 39px;
  object-fit: contain;
  margin-bottom: 30px;
  max-width: 80%;
}

.home button {
  margin-top: 15px;
}

.nav-logo {
  height: 43px;
  padding-top: 5px;
  margin-inline: auto;
  object-fit: contain;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  pointer-events: auto;
}

h1 {
  color: var(--white);
}

.top-left-container {
  position: fixed;
  display: block;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: row;
  z-index: 12;
  box-sizing: border-box;
  pointer-events: none;
}

.nav-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--dark);
  height: 80px;
  width: 91px;
  box-sizing: content-box;
  transition: background-color 0.3s ease-in-out;
  position: relative;
  margin-right: -1.5px;
  padding-right: 3px;
}

.nav-container img {
  margin-inline: 20px;
}

.nav-container::before {
  background-color: var(--fancy-border);
  content: "";
  height: calc(100%);
  width: calc(100% + 2px);
  position: absolute;
  clip-path: polygon(
    evenodd,
    0% 0%,
    100% 0%,
    100% 100%,
    0% 100%,
    0% 0%,
    0px 0px,
    calc(100% - 2px) 0px,
    calc(100% - 2px) calc(100% - 2px),
    0px calc(100% - 2px),
    0px 0px
  );
}

.nc-right {
  position: fixed;
  display: block;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  background-color: var(--dark);
}

.nc-right::before {
  background-color: var(--fancy-border);
  content: "";
  height: calc(100% + 2px);
  width: calc(100% + 2px);
  position: absolute;
  backdrop-filter: blur(500px);
  -webkit-backdrop-filter: blur(500px);
  clip-path: polygon(
    evenodd,
    2px 0%,
    100% 0%,
    100% 100%,
    0% 100%,
    0% 0%,
    0px 0px,
    100% 0px,
    100% calc(100% - 2px),
    2px calc(100% - 2px),
    2px 2px
  );
}
.btn-wrapper {
  height: 30px;
}

.btn-wrapper button {
  background-color: var(--mid) !important;
  padding-inline: 10px !important;
  border: var(--border) !important;
  margin-bottom: 0 !important;
  height: 30px;
}

.dropdown-button {
  padding-inline: 9px;
  padding-block: 6px;
  background-color: var(--mid);
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin-right: 6px;
  margin-top: 6px;
}

.dropdown-button svg {
  pointer-events: none;
}

.dropdown-button:hover {
  background-color: var(--primary);
}
.dropdown-button-open {
  background-color: var(--primary);
}
.dropdown-button svg path {
  transition: stroke 0.3s ease-in-out;
}

.dropdown-button:hover svg path {
  transition: all 0.3s ease-in-out !important;
  stroke: white !important;
}
.dropdown-button-open svg path {
  transition: all 0.3s ease-in-out !important;
  stroke: white !important;
}

.button-wrapper button {
  background-color: unset !important;
  color: unset !important;
  border: unset !important;
  border-radius: unset !important;
  line-height: unset !important;
  font-weight: unset !important;
  width: unset !important;
  margin-bottom: unset !important;
  padding: unset !important;
  font-size: unset !important;
  display: unset !important;
  transition: unset !important;
  font-family: unset !important;
  text-align: unset !important;
}

.dropdown-items {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.dropdown-item {
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  border: none;
  outline: none;
  background-color: var(--dark);
  color: var(--white);
  cursor: grab;
  text-align: right;
  font-weight: 600;
  font-size: 16px;
  transition: filter 0.2s ease-in-out;
  cursor: pointer;
  padding-right: 20px;
}

.dropdown-item:hover {
  filter: brightness(var(--button-filter));
}

.dropdown-expanded {
  width: 200px;
  height: 370px;
}

.toolbar-list-item {
  overflow: hidden;
  height: 0px;
  padding: 0px;
  opacity: 0;
  border-top: 0px;
  cursor: grab;
  color: var(--dark);
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--dark);
  color: var(--white);
}
.toolbar-wrapper:hover .toolbar-list-item {
  height: 60px;
  opacity: 1;
}
.toolbar-wrapper:hover .fake-item {
  opacity: 1;
  height: 0px;
}

.toolbar-list-item:hover {
  filter: brightness(var(--button-filter));
  transition: all 0.1s ease-in-out !important;
}

.dropzon-wrapper {
  overflow-y: scroll;
  min-height: 100vh;
}
.dashboard-dropzone {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(4, 310px);
  grid-template-rows: repeat(auto-fill, 310px);
  padding-top: calc(2rem + 45px);
  padding-bottom: 150px;
  gap: 10px;
  transition: background-color 0.2s ease-in-out;
  margin-inline: auto;
}

@media (width > 1320px) {
  .dashboard-dropzone {
    width: 1270px;
  }
}

@media (width <= 1320px) {
  .dashboard-dropzone {
    width: 1000px;
  }
}

@media (width > 2322px) {
  .dashboard-dropzone {
    width: 2320px;
    margin-inline: auto;
    justify-content: center;
    grid-template-columns: repeat(8, 310px);
  }
}

@media (width <= 500px) {
  .dashboard-dropzone {
    width: 310px;
    grid-template-columns: 310px;
  }
}

.highlight {
  background-color: #4f4f4f;
}

.widget-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  justify-content: space-between;
}
.setting-top {
  padding-inline: 40px;
}
.new-widget-top {
  padding-bottom: 10px;
  width: 600px;
}

.widget-title {
  font-weight: 500;
  user-select: none;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.dragging {
  cursor: move;
}

.dashboard-widget {
  position: relative;
  color: var(--white);
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition:
    transform 0.03s ease-in-out,
    background-color 0.3s ease-in-out,
    border 0.3s ease-in-out;
}

.breakdown-widget {
  position: relative;
  background-color: var(--dark);
  border: 2px solid var(--fancy-border);
  box-sizing: border-box;
  border-radius: 2px;
  grid-column: span 2;
  width: 630px;
  height: 570px;
  display: flex;
  padding: 20px 30px;
  flex-direction: column;
  gap: 10px;
}
.breakdown-title {
  color: var(--white);
  font-weight: 500;
}

.breakdown-widget-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 0px;
  justify-content: center;
}

.breakdown-widget-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 65%;
  gap: 30px;
  justify-content: space-between;
}

.breakdown-widget-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  gap: 0px;
  justify-content: center;
}

.breakdown-widget-bottom {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: 100%;
  gap: 0px;
  padding-top: 10px;
}
.breakdown-widget-header {
  display: flex;
  padding-bottom: 10px;
  justify-content: center;
  width: 100%;
}
.breakdown-display {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 260px;
  box-sizing: border-box;
}
.breakdown-horizontal {
  display: flex;
  flex-direction: row;
  width: calc(270px * 3);
  gap: 10px;
}
.breakdown-single {
  margin: 0px !important;
  padding: 0px;
  width: 260px !important;
  box-sizing: border-box;
}
.updates {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: -10px;
  margin-top: -45px;
  background-color: var(--mid);
  color: var(--green);
  padding: 5px 10px;
  border-radius: 3px;
  font-weight: 600;
}
.update-bubble {
  width: 10px;
  height: 10px;
  background-color: var(--green);
  border-radius: 50px;
}
.bar-left {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 3px;
  height: 100%;
  border-left: 3px solid var(--mid);
  filter: brightness(1);
}
.bar-bottom {
  margin-top: -5.5px;
  z-index: 100;
  width: 78%;
  margin-left: 10px;
  border-bottom: 3px solid var(--mid);
}
.widget-background {
  width: 310px;
  height: 310px;
  background-color: var(--dark);
  border: 2px solid var(--fancy-border);
  box-sizing: border-box;
  border-radius: 2px;
}
.table-entry-left {
  position: absolute;
  left: 0;
  height: 100%;
  width: 3px;
  z-index: 100;
}
.opaque-widget {
  background-color: var(--mid) !important;
}
.dragging-widget {
  filter: brightness(1.1) opacity(0.5) !important;
}

.settings-loading {
  position: fixed;
  bottom: 0;
  left: 0;
  margin-left: 25px;
  margin-bottom: 25px;
}
.settings-loading::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  background-color: var(--primary);
  filter: brightness(0.8);
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(1);
  animation: ripple 1.2s infinite ease-in-out;
  pointer-events: none;
}

.loading-widget::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  background-color: var(--light);
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(1);
  animation: ripple 1.2s infinite ease-in-out;
  pointer-events: none;
}

.loading-widget-small::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 15px;
  height: 15px;
  background-color: var(--light);
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0.5);
  animation: ripple 1.2s infinite ease-in-out;
  pointer-events: none;
}

.loading-widget-small:hover {
  filter: none !important;
  cursor: unset !important;
}

.context-menu {
  border: 2px solid var(--fancy-border);
  background-color: var(--mid);
  display: flex;
  flex-direction: column;
}
.context-menu button {
  width: 100%;
  border-radius: 0;
  padding: 20px;
}
.context-hl {
  width: 50%;
  height: 2px;
  background-color: var(--light);
  margin-inline: auto;
}
.line-table-loading {
  width: 100%;
  height: 100px;
  z-index: 10;
}

.line-table-loading-widget::before {
  content: "";
  position: relative;
  display: inline-block;
  top: 60px;
  width: 30px;
  height: 30px;
  background-color: var(--light);
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(1);
  animation: ripple 1.2s infinite ease-in-out;
  pointer-events: none;
}

@keyframes ripple {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}

.open-widget-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: #00000000;
  padding-top: calc(2rem + 45px);
  padding-bottom: 100px;
  top: 0;
  left: 0;
  overflow-y: scroll;
  box-sizing: border-box;
}

.new-widget-overlay {
  align-items: center;
}

.dropdown-overlay {
  position: absolute;
  width: 100vw;
  height: 100vh;
}

.dashboard-widget-open {
  position: relative;
  color: var(--less-white);
  width: 100vw !important;
  height: 70px;
  z-index: 11;
  opacity: 1;
  cursor: unset;
  border: none !important;
  background-color: var(--background);
}

.open-widget-top-container {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: var(--dark);
  z-index: 15;
}

.open-widget-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: sticky;
  align-items: flex-start;
  width: 100%;
  margin-inline: auto;
  height: 60px;
  padding-right: 40px;
  padding-left: 13px;
  box-sizing: border-box;
  border-bottom: 2px solid var(--fancy-border);
}

.open-widget-left {
  display: flex;
  gap: 25px;
  align-items: center;
}

.open-widget-title {
  margin-top: 7px;
  display: flex;
  gap: 20px;
}

.pmt {
  margin-top: 7px !important;
}

.open-widget-title h2 {
  padding: 0 !important;
  margin: 0 !important;
}

.open-widget-close {
  margin-top: 15px;
}
.dashboard-widget-open .x-button {
  opacity: 0.8;
}

.tracked-job:hover .x-button {
  opacity: 0.4;
}
.feedback-card:hover .x-button {
  opacity: 0.4;
}

.dashboard-widget-open .widget-top {
  z-index: 10;
}
.dashboard-widget-open:hover .settings-button svg {
  opacity: 0.4;
}

.settings-svg path {
  stroke: var(--less-white);
}

.setting-widget {
  position: fixed;
  z-index: 18 !important;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 33%;
  min-height: 75%;
  z-index: 11;
  opacity: 1;
  cursor: unset;
  border-radius: 0px;
  background-color: var(--dark);
  border: 2px solid var(--fancy-border);
  box-sizing: border-box;
}
.settings {
  width: 100%;
  max-height: 80vh;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  scroll-behavior: smooth;
}
.setting-wrapper {
  width: 100%;
  position: relative;
}
.setting {
  padding-top: 25px;
  padding-bottom: 26px;
  padding-inline: 10%;
  position: relative;
  background-color: var(--dark);
  font-size: 16px;
  transition: background-color 0.3s ease-in-out;
}

.dropdown-setting {
  border: none;
  padding-top: 25px;
  padding-bottom: 26px;
  padding-inline: 10%;
  background-color: var(--dark);
  font-size: 16px;
  width: 100%;
  color: var(--white);
  transition: background-color 0.3s ease-in-out;
  z-index: 13;
}
.dropdown-setting:hover {
  filter: brightness(var(--button-filter));
}

.dropdown-setting-open:hover {
  filter: none;
}
.setting-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.setting::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100%;
  height: 1px;
  background-color: rgba(135, 123, 123, 0.32);
  transform: translateX(-50%);
}
.setting:last-child::after {
  display: none;
}
.setting-button {
  z-index: 5;
  padding-block: 10px;
  padding-inline: 15px;
  pointer-events: all;
  border: none;
  color: var(--white);
  cursor: pointer;
  background: none;
  border-radius: 1px;
}
.setting-button:hover {
  background-color: var(--mid);
}
.button-row {
  position: relative;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  gap: 3px;
}
.button-overlay {
  z-index: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  width: calc(50% - 2px);
  background-color: var(--primary);
  border-radius: 1px;
}
.triple-button {
  width: calc(33% - 2px);
}
.active-button {
  background-color: var(--primary);
  color: white;
}
.active-button:hover {
  filter: none;
  background-color: var(--primary) !important;
}
.active-color-button {
  background: var(--mid);
}
.active-color-button:hover {
  background: var(--light);
}
.color-scheme-picker {
  padding-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 5px;
  column-gap: 5px;
  width: 95%;
  margin-inline: auto;
}
.color-palette {
  position: relative;
  z-index: 10;
}

.color-scheme {
  padding-block: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  align-items: center;
}

.wide-widget {
  grid-column: span 2;
  width: 630px;
}

.clickable-widget {
  transition: all 0.3s ease-in-out;
}

.clickable-widget:hover {
  cursor: pointer;
  transform: scale(1.015);
}

.text-widget {
  cursor: unset;
}

.overlay-widget {
  cursor: grabbing;
}

.drag-handle {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  height: 7px;
  width: 7px;
  border-radius: 20px;
  background-color: var(--white);
  opacity: 0.5;
  margin-right: 10px;
  transition: all 0.2s ease-in-out;
}

.drag-handle-wrapper {
  justify-self: center;
  align-self: center;
  width: 35px;
  height: 35px;
}

.drag-handle-wrapper:hover .drag-handle {
  height: 20px;
  width: 20px;
  transform-origin: center;
  transition: all 0.2s ease-in-out;
}

.tracked-job:hover span.grippy {
  opacity: 0.5;
}
span.grippy {
  content: "....";
  width: 10px;
  height: 30px;
  display: inline-block;
  overflow: hidden;
  line-height: 7px;
  padding: 3px 4px;
  cursor: move;
  font-weight: 600;
  vertical-align: middle;
  margin-right: 10px;
  font-size: 20px;
  font-family: sans-serif;
  letter-spacing: 1.5px;
  color: var(--less-white);
  justify-self: center;
  align-self: center;
  opacity: 0;
}
span.grippy::after {
  content: ".. .. ..";
}

.x-button {
  cursor: pointer;
  opacity: 0;
  background: none;
  border: none;
  right: 0;
}
.x-button:hover {
  opacity: 0.6 !important;
}

.dashboard-widget .x-button {
  pointer-events: none;
}
.dashboard-widget .x-button:hover {
  opacity: 0 !important;
}

.drag-handle:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
  opacity: 0.6 !important;
}

.tooltip {
  border-radius: 2px;
  padding-block: 5px;
  padding-inline: 8px;
  background-color: var(--mid);
  font-size: 1rem;
  color: var(--white);
  display: flex;
  gap: 5px;
  align-items: center;
  position: relative;
  z-index: 25 !important;
}
.slice {
  flex-direction: column;
  align-items: flex-start;
}
.single-slice {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.tooltip-cube {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.noscroll {
  overflow: hidden;
  height: 100vh;
  box-sizing: border-box;
}
.chart-display-wrapper {
  margin-left: -47px;
  margin-top: -10px;
}
.chart-wrapper {
  width: 350px;
  height: 350px;
}

.legend-box {
  display: block;
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding-inline: -20px;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  max-height: 300px;
  min-width: 250px;
  overflow-y: scroll;
  background-color: var(--background);
  z-index: 10;
  box-sizing: border-box;
}

.legend {
  text-align: left;
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
  padding-inline: 5px;
  padding-block: 5px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: var(--background);
}

.legend:hover {
  background-color: var(--activePrimary);
}

.no-legend-click {
  cursor: unset;
  pointer-events: none;
  flex-direction: row;
  gap: 20px;
  margin-top: -15px !important;
  padding-bottom: 20px;
}

.open-chart-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  margin-inline: auto;
  box-sizing: border-box;
  padding-top: 0px;
}
.col-chart-legend {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.row-chart-legend {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.open-widget-container {
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: calc(100vh - 70px);
  margin-inline: auto;
  border-radius: 2px;
  background-color: var(--dark);
  border-top: 2px solid var(--fancy-border);
  box-sizing: border-box;
}

.open-widget-container h2 {
  position: sticky;
  top: -20px;
  margin-top: 0px;
  padding-top: 0px;
  padding-bottom: 3px;
  margin-bottom: 40px;
  background-color: var(--dark);
  z-index: 12;
}

.scroll-open-widget {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: scroll;
  padding-bottom: 300px;
  padding-top: 20px;
}

.pie-chart-row {
  display: flex;
  flex-direction: row;
  padding-left: 50px;
}

.table-wrapper {
  position: absolute;
  width: 85%;
  margin-inline: auto;
  margin-top: -20px;
  margin-bottom: 50px;
  height: fit-content;
}

.line-table {
  overflow: scroll;
  left: 0;
  padding-bottom: 20px;
}

.table-wrapper.with-shadow::before {
  content: "";
  position: absolute;
  right: 0;
  height: calc(100% - 20px);
  width: 6px;
  box-shadow: inset -10px 0 8px -10px var(--shadow);
  pointer-events: none;
}

.line-table table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.line-table th,
.line-table td {
  padding: 10px 15px;
  text-align: left;
  width: 120px;
}
.active-column {
  filter: brightness(1.3);
}
.line-table th {
  background-color: var(--mid-highlight);
  color: var(--white);
  font-size: 14px;
}
.line-table td {
  font-size: 14px;
  border-bottom: 1px solid var(--mid-highlight);
  height: 30px;
  background-color: var(--dark);
}
.line-table td:last-child,
.line-table th:last-child {
  border-right: 1px solid var(--mid-highlight);
}

.positive-val {
  color: #5bce53;
  font-weight: 500;
}
.negative-val {
  color: #df5050;
  font-weight: 500;
}

.line-table th:first-child,
.line-table td:first-child {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: var(--mid-highlight);
  color: var(--white);
  font-weight: 800;
}

.p-datatable-tbody tr td * {
  fill: var(--light);
}

.line-table th:first-child {
  opacity: 0.8;
}

.table-loading-wrapper {
  height: 400px;
}
.loading-wrapper {
  width: 100%;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.settings-button {
  position: absolute;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  bottom: 15px;
  right: 15px;
  background-color: var(--dark);
  border: none;
  cursor: pointer;
  transform: rotate(90deg);
  z-index: 10;
}
.settings-button svg {
  width: 40px;
  height: 40px;
  margin-left: -1px;
  opacity: 0;
}

.settings-button:hover svg {
  opacity: 0.8 !important;
}
.animated-circle {
  transition: cy 0.3s ease-in-out;
}

.editing-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--dark);
  z-index: 5;
  padding-top: 50px;
  border-radius: 150px 0px 0px 0px;
  box-sizing: border-box;
}

.item-settings {
  width: 80%;
  margin-inline: auto;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.modifier-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.modifier-button {
  background-color: var(--mid-highlight);
  border: none;
  position: relative;
  flex: 1;
  padding-block: 24px;
  cursor: pointer;
  color: var(--white);
}

.modifier-button:hover {
  filter: brightness(0.95);
}

.option-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  height: calc(45vh + 30px);
  overflow: scroll;
}

.option-button {
  min-width: 250px;
  height: 50px;
  cursor: pointer;
  padding: unset;
}
.modifier-button-row {
  display: flex;
  justify-content: center;
  gap: 20px;
}
.mod-btn {
  opacity: 1;
  width: 100px;
  padding-block: 10px;
  border-radius: 1px;
  border: none;
  font-weight: 500;
  cursor: pointer;
  color: white;
  background-color: var(--dark);
  transition: all 0.3s ease-in-out;
}
.mod-btn-hidden {
  opacity: 0;
}
.mod-btn:hover {
  filter: brightness(var(--button-filter));
  color: #55555545;
}
.modifier-save {
  font-size: 16px;
}
.modifier-cancel {
  font-size: 14px;
}

.option-button-active {
  filter: brightness(0.9);
}
.option-button-active:hover {
  filter: brightness(0.9);
}
.topbar-button {
  height: 46px;
  width: 46px;
  position: relative;
  padding: 6px 6px;
  border: none;
  cursor: pointer;
  background-color: var(--mid);
  transform-origin: center;
}

.topbar-button:hover {
  filter: brightness(0.95);
}

.topbar-button-disabled {
  filter: brightness(var(--filter1));
}

.topbar-button-disabled:hover {
  filter: brightness(var(--filter1));
  cursor: unset;
}

.topbar-button-disabled svg {
  filter: brightness(var(--filter2));
  cursor: unset;
}

.topbar-svg {
  stroke: var(--less-white);
}

.topbar-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 20px;
  gap: 10px;
  padding-top: 15px;
  pointer-events: auto;
}

.divider-line {
  width: 20px;
  height: 2px;
  border-radius: 2px;
  left: 0;
  background-color: var(--less-white);
  filter: opacity(0.3);
  margin-bottom: 5px;
}

.project-tabs {
  padding-inline: 12px;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  height: 60px;
  transition: background-color 0.3s ease-in-out;
  z-index: 1;
  background-color: var(--dark);
  border-bottom: 2px solid var(--fancy-border) !important;
  border-right: 2px solid var(--fancy-border) !important;
  box-sizing: border-box;
  margin-left: -1.8px;
  max-width: calc(95vw - 200px);
  overflow-y: scroll;
}
.pt-left-border {
  border-left: 2px solid var(--fancy-border) !important;
  margin-left: 0px;
}

.project-button {
  border: none;
  outline: none;
  color: var(--white);
  background-color: var(--mid);
  cursor: pointer;
  gap: 0px;
  padding: 8px 15px;
  border-radius: 2px;
  font-size: 14px;
  height: 32px;
  position: relative;
  width: max-content;
  word-break: keep-all;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}
.project-button svg {
  width: 24px !important;
  height: 24px !important;
}

.project-button:hover {
  filter: brightness(var(--filter3));
}
.active-project-button {
  background-color: var(--primary);
  color: white;
}

.more-padding {
  padding: 16px 30px;
  height: unset;
}
.recommended-jobs {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 600px;
}
.modifier-options {
  padding-inline: 12px;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: fit-content;
  transition: background-color 0.3s ease-in-out;
  z-index: 1;
  background-color: var(--dark);
  border-bottom: 2px solid var(--fancy-border) !important;
  border-right: 2px solid var(--fancy-border) !important;
  box-sizing: border-box;
  margin-left: -1.8px;
}

.modifier-span {
  color: var(--less-white);
  font-size: 14px;
}

.select-dropdown {
  min-width: 220px;
  width: fit-content !important;
  padding-left: 10px !important;
  height: 40px;
  border-radius: 0px !important;
  color: var(--white) !important;
  background-color: var(--more-mid);
  border-radius: 2px !important;
  border: none !important;
}

.select-dropdown-small {
  min-width: 100px !important;
  width: 100px !important;
}
.select-dropdown:focus-within {
  outline: none;
  box-shadow: none !important;
}

.react-dropdown-select-content {
  font-size: 14px !important;
  overflow: hidden;
}

.react-dropdown-select-type-single:focus-within {
  outline: none;
  box-shadow: none !important;
}

.react-dropdown-select-input {
  color: var(--white) !important;
  font-weight: 500;
  text-align: left !important;
  font-size: 14px !important;
}

.react-dropdown-select-dropdown {
  background-color: var(--mid) !important;
  text-align: left;
  border: none !important;
}

.react-dropdown-select-dropdown:focus-within {
  box-shadow: none !important;
}

.react-dropdown-select-item {
  padding-block: 13px !important;
  border: none !important;
  font-size: 14px !important;
}
.react-dropdown-select-item:hover {
  filter: brightness(var(--filter5));
  background-color: var(--mid) !important;
}

.react-dropdown-select-item-selected {
  filter: brightness(var(--filter4)) !important;
  background-color: var(--primary) !important;
}
.react-dropdown-select-item-selected:hover {
  filter: brightness(var(--filter4)) !important;
  background-color: var(--primary) !important;
}

.react-dropdown-select-no-data {
  color: var(--less-white) !important;
  font-size: 14px !important;
}

.clear-modifiers {
  background: none;
  border: none;
  padding-top: 5px;
  cursor: pointer;
  filter: brightness(var(--filter6));
  margin-left: -5px;
  pointer-events: auto;
}

.clear-modifiers:hover {
  filter: brightness(var(--filter4));
}

.popup-wrapper {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 215;
  pointer-events: none;
}
.new-widget-popup {
  min-width: 600px;
  width: fit-content;
  min-height: 450px;
  height: fit-content;
  padding-inline: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
  background-color: var(--dark);
  border: 2px solid var(--fancy-border);
  color: var(--less-white);
  pointer-events: auto;
  position: relative;
}

.new-widget-button-row {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 20px;
}

.widget-type-button {
  height: 150px;
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 15px 18px;
  border: none;
  cursor: pointer;
  background-color: var(--mid);
  transition: all 0.1s ease-in-out;
  color: var(--white);
}
.widget-type-button:hover {
  filter: brightness(0.95);
}

.widget-type-button svg {
  width: 50px;
  margin-inline: auto;
}

.widget-button-row {
  width: 630px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-inline: auto;
  gap: 15px;
  margin-bottom: 15px;
}
.widget-button {
  width: 200px;
  height: 60px;
  border: none;
  outline: none;
  background-color: var(--mid);
  color: var(--white);
  transition:
    background-color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.widget-button:hover {
  filter: brightness(0.95);
}
.widget-button-header {
  margin-bottom: 10px;
  text-align: left;
  font-weight: 500;
  padding-left: 5px;
}

.mirrored > svg {
  transform: scaleX(-1);
}

.system-message {
  position: fixed;
  width: fit-content;
  top: 0;
  margin-inline: auto;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 20px;
  font-size: 14px;
  font-weight: 500;
  border: 2px solid var(--fancy-border);
  background-color: var(--dark);
  padding: 8px 12px;
  color: var(--less-white);
  z-index: 12;
}
.empty-space {
  width: 50px;
}
.single-margin-container {
  display: flex;
  justify-content: center;
  gap: 30px;
  align-items: center;
  height: 230px;
  min-width: 640px;
  width: 100%;
  box-sizing: border-box;
}

.big-margin-text {
  font-size: 80px;
  font-weight: 900;
}

.shift-right {
  padding-left: 60px;
}

.shift-right .legend-box {
  margin-left: -60px;
}
.over {
  color: var(--red) !important;
}
.under {
  color: var(--green);
}

.light-red {
  background-color: #ff204995;
}
.ytd-display {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  height: calc(var(--widget-size) - 80px);
  gap: 8px;
  margin-top: -5px;
}
.ytd-open {
  width: clamp(300px, 50vw, 800px);
}
.ytd-single {
  display: flex;
  flex-direction: column;
  padding: 4px 8px;
  background-color: var(--mid);
  width: 80%;
  margin-inline: auto;
  border-radius: 5px;
  flex: 1;
}
.ytd-single > p {
  text-align: left;
  margin: 0;
  font-size: 14px;
  color: var(--less-white);
}
.ytd-single > h2 {
  text-align: center;
  margin: 0;
}
.ytd-no-margin {
  gap: 5px;
}
.ytd-no-admin {
  justify-content: center;
  gap: 15px;
}
.ytd-no-admin > p {
  text-align: center;
}
.single-open {
  padding: 20px 30px;
}
.single-open > p {
  text-align: left;
  margin: 0;
  font-size: 18px;
  color: var(--less-white);
}

.single-open > h2 {
  text-align: center;
  margin: 0;
  font-size: 32px;
}

.job-display {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-inline: 15px;
  padding-bottom: 15px;
  width: calc(var(--widget-size) - 6px);
  height: calc(var(--widget-size) - 60px);
  box-sizing: border-box;
}
.open-job-display {
  width: clamp(300px, 40vw, 2000px);
  height: clamp(300px, 80vh, 2000px);
}
.open-job-display .filtered-jobs {
  height: unset;
}
.job-display-single {
  justify-content: flex-start;
  margin-top: -10px;
  gap: 10px;
}
.job-display-single h2 {
  margin: 0;
  font-size: 60px;
}

.job-display-single strong {
  font-size: 20px;
}

.job-display-all {
  position: relative;
  padding: 0px;
  width: 100%;
  align-items: center;
  gap: 20px;
}

.job-display-content {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-inline: auto;
  box-sizing: border-box;
}
.job-display-section {
  background-color: var(--mid);
  width: 50%;
  padding: 18px 18px;
  min-height: 60px;
  border-radius: 4px;
}
.all-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-section {
  cursor: pointer !important;
}
.btn-section:hover {
  filter: brightness(var(--filter3));
}

.btn-drag-over {
  background-color: var(--primary);
}

.job-display-back {
  background-color: var(--dark);
}
.item-background {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: flex-start;
  gap: 5px;
  flex-wrap: wrap;
  width: fit-content;
  min-width: 100%;
  height: fit-content;
  max-height: 135px !important;
  overflow-y: scroll;
}
.item-button {
  border: none;
  outline: none;
  color: var(--white);
  background-color: var(--mid);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0px;
  padding: 10px 20px;
  border-radius: 2px;
  width: 63%;
  font-size: 16px;
  font-weight: 600;
}

.item-button * {
  margin: 0;
}
.item-button > p {
  text-align: left;
  margin: 0;
  font-size: 12px;
  color: var(--less-white);
}
.item-button > h2 {
  text-align: center;
  margin: 0;
  font-size: 20px;
}
.item-button:hover {
  filter: brightness(var(--filter3));
}

.job-display-status {
  margin-top: 15px;
  font-weight: 700;
  font-size: 20px;
}
.job-display-arrow {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}
.job-display-arrow svg path {
  stroke: var(--light);
  opacity: 0.5;
}
.job-display:hover .job-display-arrow svg path {
  opacity: 0.5;
}
.job-display-arrow:hover svg path {
  opacity: 1.5 !important;
}

.arrow-hidden {
  opacity: 0;
  cursor: unset;
}

.status-Active {
  color: var(--green);
}
.status-Complete {
  color: var(--accent);
}

.dots-container {
  width: 70%;
  overflow-x: scroll;
  margin-inline: auto;
  scrollbar-width: none;
}
.dots-container::-webkit-scrollbar {
  display: none;
}
.phase-dots {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  gap: 7px;
  width: fit-content;
  margin-inline: auto;
}
.phase-dot {
  width: 10px;
  height: 10px;
  background-color: var(--light);
  border-radius: 50%;
}
.phase-dot-Active {
  background-color: var(--green);
}
.phase-dot-Complete {
  background-color: var(--accent);
}

.job-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-inline: auto;
  gap: 10px;
}

.job-button {
  border: none;
  outline: none;
  color: var(--white);
  background-color: var(--mid);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0px;
  padding: 8px 15px;
  border-radius: 2px;
  width: 85px;
  font-size: 14px;
}
.job-phase {
  font-size: 22px;
}
.open-job-button {
  width: 180px;
}
.job-button * {
  margin: 0;
}
.job-button > p {
  text-align: left;
  margin: 0;
  font-size: 12px;
  color: var(--less-white);
}
.job-button > h2 {
  text-align: center;
  margin: 0;
  font-size: 20px;
}
.job-button:hover {
  filter: brightness(var(--filter3));
}

.active-job-button {
  background-color: var(--primary);
  color: white;
}
.active-job-button p {
  color: white;
}
.filtered-jobs {
  display: flex;
  flex-direction: column;
  height: 228.5px;
  gap: 2px;
  overflow-y: scroll;
  background-color: var(--mid);
  border-radius: 3px;
}
.filtered-job-button {
  padding-block: 10px;
  background: var(--mid);
  border: none;
  color: var(--less-white);
  text-align: left;
  padding-inline: 25px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.filtered-job-button > p {
  margin: 0;
}
.filtered-job-button:hover {
  filter: brightness(var(--filter3));
}

.sticky-job {
  position: sticky;
  top: 0;
  background-color: var(--mid);
  z-index: 8;
  padding: 6px !important;
  font-weight: 600;
  padding-left: 10px !important;
  text-align: left;
  cursor: pointer;
  box-sizing: border-box;
}

.no-item-text {
  color: var(--less-white);
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
}
.no-item-text h3 {
  margin-top: 0;
}

.no-item-text h3:hover {
  text-decoration: underline;
  cursor: pointer;
}

.data-display-wrapper {
  width: 80vw;
  max-width: 1150px;
  overflow-x: scroll;
  border-radius: 5px 5px 5px 5px;
}

.data-table {
  display: flex;
  flex-direction: column;
  gap: 1px;
  min-width: 100%;
  width: fit-content;
}
.table-header-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  top: 12px;
  z-index: 13;
  background-color: var(--mid);
  height: 60px;
  width: fit-content;
  min-width: 100%;
}

.no-click {
  cursor: unset !important;
}
.table-header-item {
  text-align: left;
  background-color: var(--mid);
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  width: 200px;
  box-sizing: border-box;
}

.table-header-item:hover {
  filter: brightness(var(--filter3));
}

.table-body {
  display: flex;
  flex-direction: column;
  gap: 1px;
  font-size: 14px;
  width: fit-content;
  min-width: 100%;
}

.table-entry {
  position: relative;
  display: flex;
  flex-direction: column;
  background: none;
  color: var(--less-white);
  border: none;
  padding: 0;
  min-width: 100%;
}

.table-entries {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 11;
  min-width: 100%;
  background-color: var(--more-mid);
}

/* .table-entries-open {
  border-bottom: 2px solid var(--light);
  position: sticky;
  font-weight: 700;
  top: 55px;
} */

.hoverable-entry:hover {
  filter: brightness(var(--filter3));
  cursor: pointer;
}
.table-entry-item {
  text-align: left;
  display: flex;
  align-items: center;
  background-color: var(--more-mid);
  padding: 10px;
  height: 60px;
  width: 200px !important;
  box-sizing: border-box;
}
.sub-table {
  width: 98%;
  margin-top: 17px;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.sub-data-container {
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
  display: flex;
  z-index: 10;
}
.sub-arrow-wrapper {
  position: relative;
}

.sub-arrow {
  position: sticky;
  top: 85px;
  padding-inline: 8px;
  padding-top: 20px;
}
.sub-header-row {
  z-index: 10;
}

.sub-header-row .table-header-item {
  padding: 5px;
  padding-inline: 10px;
}

.entry-nestlevel-0 {
  top: 55px;
}
.header-nestlevel-0 {
  top: 85px;
  z-index: 10;
}
.entry-nestlevel-1 {
  top: 135px;
  z-index: 9;
}
.header-nestlevel-1 {
  top: 165px;
  z-index: 8;
}

.entry-nestlevel-2 {
  top: 215px;
  z-index: 7;
}
.header-nestlevel-2 {
  top: 245px;
  z-index: 6;
}
.header-nestlevel-3 {
  top: 325px;
  z-index: 4;
}
.entry-nestlevel-3 {
  top: 295px;
  z-index: 5;
}
.sub-entries {
  display: flex;
  flex-direction: row;
  width: fit-content;
  min-width: 100%;
  justify-content: space-between;
  background-color: var(--more-mid);
}

.dashboard-welcome {
  width: clamp(300px, 80vw, 1200px);
  margin-inline: auto;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.open-widget-page {
  gap: 1rem;
}
.addjobs-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  height: 60vh;
}

.addjobs-button {
  position: relative;
  border: 2px solid var(--fancy-border);
  background-color: var(--dark);
  width: calc(var(--widget-size) / 0.5);
  height: calc(var(--widget-size) / 1.6);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding-inline: 20px;
  color: var(--white);
  cursor: pointer;
  transition: filter 0.3s ease-in-out;
  box-sizing: border-box;
}

.addjobs-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  background-color: var(--dark);
  z-index: 0;
  transition: transform 0.3s ease-in-out;
  transform-origin: center;
  transform: scale(1);
}

.addjobs-button:hover {
  filter: brightness(var(--filter3));
}

.addjobs-button span {
  font-weight: 600;
}

#plus svg {
  width: 52px;
  height: 52px;
  transition: transform 0.3s ease-in-out;
  transform-origin: center;
}

.addjobs-button:hover #plus svg {
  transform: scale(1.2);
}
.recommended {
  padding-bottom: 20px;
}

.add-button {
  background: var(--mid);
  border: none;
  padding-right: 17px;
  border-radius: 0px;
  cursor: pointer;
  color: var(--white);
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-weight: 500;
  width: 250px;
  padding-block: 12px;
  height: unset;
  font-size: 14px;
  border-radius: 2px;
}
.add-button:hover {
  filter: brightness(var(--filter3));
  color: var(--less-white);
}
.add-button:hover svg path {
  filter: brightness(var(--filter3));
  stroke: var(--less-white) !important;
}
.add-button svg {
  height: 25px;
}

.add-button svg path {
  stroke: var(--shadow) !important;
}

.job-selector-body {
  position: relative;
  display: flex;
  height: 100%;
  margin-inline: auto;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  min-height: 350px;
}
.job-selector-row {
  display: grid;
  grid-template-columns: 250px 250px;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
  padding-bottom: 20px;
}
.job-selector-item {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  border-radius: 2px;
  cursor: pointer;
  width: fit-content;
}
h1 {
  font-size: 3.7rem;
}

.job-selector-item .x-button {
  opacity: 0.4;
}

.co-select-dropdown {
  background-color: var(--background) !important;
  border: 1px solid var(--background) !important;
  border-radius: 5px !important;
  padding: 15px 30px !important;
  color: var(--white) !important;
  font-size: 16px !important;
  cursor: pointer;
  width: 100% !important;
}

.co-select-dropdown:focus-within {
  outline: none;
  box-shadow: none !important;
  background-color: var(--mid);
}

.job-selector-item .job-select-dropdown {
  background-color: var(--mid);
  border-radius: 2px !important;
  padding-block: 25px !important;
  justify-content: center !important;
}
.job-select-dropdown {
  position: relative !important;
  padding-left: 10px !important;
  height: 40px;
  border-radius: 0px !important;
  color: var(--white) !important;
  background-color: var(--more-mid);
  border: none !important;
  box-sizing: border-box;
  width: 250px !important;
  cursor: pointer !important;
}

.job-select-dropdown:focus-within {
  outline: none;
  box-shadow: none !important;
  background-color: var(--mid);
}

.job-select-dropdown .react-dropdown-select-option {
  padding: 5px 10px;
}
.active-job-select .job-select-dropdown {
  background-color: var(--primary) !important;
}

.job-select-dropdown.react-dropdown-select {
  width: 500px !important;
  height: unset !important;
  padding: 10px 5px !important;
}

.add-job-button {
  align-self: center;
  justify-self: flex-end;
  font-size: 14px;
}

.jobs-header {
  width: calc(100% + 10px);
  display: flex;
  gap: 10px;
  align-items: baseline;
  color: var(--white);
  position: sticky;
  top: 0px;
  background-color: var(--background);
  box-sizing: border-box;
  z-index: 11;
}

.jobs-header h2 {
  margin-bottom: 10px;
}

.add-new-button {
  font-size: 1rem;
  width: fit-content;
  height: fit-content;
  opacity: 0.4;
  background-color: var(--background);
}

.add-new-button:hover {
  opacity: 0.7;
}

.tracked-jobs {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 10px;
  gap: 15px;
  padding-bottom: 180px;
}

.tracked-job {
  background-color: var(--dark);
  border: 2px solid var(--fancy-border);
  height: 150px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-inline: 20px;
  position: relative;
  gap: 20px;
}

.tracked-job .x-button {
  justify-self: flex-end;
}
.job-body {
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
}

.job-left {
  display: flex;
  flex-direction: column;
  color: var(--white);
  justify-content: center;
  text-align: left;
  height: 100%;
  gap: 10px;
}

.job-right {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.hl-jobline {
  width: 2px;
  height: 30px;
  background-color: var(--mid-highlight);
}
.job-left h2 {
  margin: 0px !important;
}
.job-left h4 {
  margin: 0px !important;
  font-weight: 400;
}

.margin-button {
  gap: 10px;
  padding: 15px 15px;
  width: 95px;
  height: 95px;
  flex-shrink: 0;
  flex-grow: 0;
}

.job-cost-header {
  position: fixed;
  display: flex;
  top: 0;
  z-index: 12;
  margin-left: 81px;
}
.switch-button-container {
  background-color: var(--dark);
  border: 2px solid var(--fancy-border);
  border-top: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
}

.switch-button:hover {
  filter: brightness(var(--filter3));
}

@media (width <= 500px) {
  .dashboard-welcome {
    padding-top: 80px;
    width: 90vw;
  }
  .add-new-button {
    display: none;
  }
  h1 {
    font-size: 2.3rem;
    text-align: left;
  }
  .tracked-job {
    height: 220px;
  }
  .tracked-job .grippy {
    display: none;
  }
  .tracked-job .x-button {
    display: none;
  }
  .job-body {
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
  .job-left {
    height: fit-content;
    gap: 2px;
    text-align: center;
  }
  .job-right {
    overflow-y: scroll;
    justify-content: unset;
    padding-right: 20px;
  }
  .margin-button {
    gap: 10px;
    padding: 15px 15px;
    width: 85px;
    height: 85px;
    pointer-events: none;
  }
  .hl-jobline {
    display: none;
  }

  .dashboard-welcome h2 {
    font-size: 18px !important;
  }
  .dashboard-welcome h3 {
    font-size: 20px !important;
  }
  .dashboard-welcome h4 {
    font-size: 14px !important;
  }
  .jobs-header {
    width: 100%;
  }
  .jobs-header h2 {
    width: 100%;
    text-align: center !important;
  }
}

.home-widget-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  padding-bottom: 40px;
}

.home-widget {
  background-color: var(--dark);
  border: 2px solid var(--fancy-border);
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  box-sizing: border-box;
}

.reports {
  padding-top: 30px;
  width: 75%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.report-card {
  background-color: var(--dark);
  border: 2px solid var(--fancy-border);
  height: 160px;
  box-sizing: border-box;
  color: var(--white);
  padding: 2.5rem;
  display: flex;
  align-items: center;
  gap: 2.5rem;
  text-align: left;
  width: 100%;
  cursor: pointer;
}

.report-page h1 {
  margin: 0px !important;
}
.report-open-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% + 10px - 3rem);
  margin-block: 1rem;
  margin-top: 2.3rem;
}
.run-svg svg {
  width: 40px;
  height: 40px;
}

.run-svg-button {
  background-color: var(--background);
  color: var(--white);
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 3px;
  margin-top: 10px;
  box-sizing: border-box;
}
.run-svg-button:hover {
  background-color: var(--dark);
}

.run-button {
  width: 60px;
  height: 55px;
  background-color: var(--dark);
  color: white;
  border: 2px solid var(--fancy-border);
  cursor: pointer;
}
.run-button-wide {
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  font-size: 14px;
  font-weight: 600;
  background: none;
  border: none;
  margin-top: 40px;
}
.run-button:hover {
  filter: brightness(var(--filter3));
  color: var(--green);
}

.report-card h2 {
  margin: 0px;
}
.last-ran {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.clickable-home {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.clickable-home:hover {
  transform: scale(1.02);
}
.home-widget-sm {
  width: 180px;
}
.home-widget-m {
  width: 350px;
}
.home-widget-long {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 730px;
  padding-inline: 35px;
  height: 150px;
  gap: 50px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.home-widget-long-loading {
  display: flex;
  justify-content: center;
  width: 730px;
  height: 150px;
  margin-bottom: 10px;
  box-sizing: border-box;
}
.home-widget-title {
  color: var(--white);
  font-weight: 500;
}

.home-widget-num {
  font-size: 5rem;
  font-weight: 600;
  color: var(--white);
  height: min-content;
  line-height: 4.5rem;
}

.smaller-num {
  font-size: 2.5rem;
  line-height: 3rem;
  text-align: right;
}

.home-widget-loading::before {
  content: "";
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  background-color: var(--light);
  border-radius: 50%;
  margin-left: 30px;
  animation: ripple 1.2s infinite ease-in-out;
  pointer-events: none;
}
.home-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.home-toggle-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-toggle-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  padding: 5px 5px;
  padding-left: 0px;
  gap: 5px;
  height: fit-content;
}

.home-toggle {
  width: fit-content;
  padding-inline: 10px;
  background: none;
  height: 40px;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  color: var(--white);
  opacity: 0.4;
}

.home-toggle:hover {
  opacity: 0.7;
}

.active-home-button {
  color: var(--white);
  opacity: 1;
  order: -1;
  padding-left: 0px;
}

.active-home-button:hover {
  opacity: 1;
}

.active-home-button svg path {
  stroke: var(--dark-white) !important;
}

@media (width <= 500px) {
  .home-toggle-container {
    width: 100%;
  }

  .home-widget-container {
    gap: 15px;
    flex-wrap: wrap;
    padding-bottom: 40px;
  }
  .home-widget-sm {
    width: 100%;
    height: 120px;
  }
  .home-widget-m {
    width: 100%;
  }
}

.image-body {
  border: none;
  outline: none;
}

.visible-image {
  opacity: 1;
}
.invisible-image {
  opacity: 0;

  position: absolute;
}
.invisible-container {
  pointer-events: none;
  overflow: hidden;
  height: 0px !important;
  width: 0px !important;
}

.cost-wrapper {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.user-page {
  padding-bottom: 150px;
}
.user-lists {
  display: flex;
  gap: 30px;
  width: clamp(400px, 100%, 1500px);
  margin-left: -20px;
}

.user-cards {
  width: clamp(400px, 50%, 1000px);
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.user-list {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 10px;
  padding: 20px;
  padding-block: 30px;
  box-sizing: content-box;
  border-radius: 5px;
}
.user-list h2 {
  color: var(--white);
  text-align: left;
  margin: 0;
}
.user-card {
  background-color: var(--dark);
  border: 2px solid var(--fancy-border);
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-inline: 30px;
  position: relative;
  gap: 20px;
  overflow: hidden;
}

.card-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
}
.card-left * {
  text-align: left;
}
.user-card h3,
p {
  color: var(--white);
  margin: 0;
}

.user-card p {
  color: var(--less-white);
}

.background-icon svg {
  height: 40px;
  width: 40px;
  z-index: 0;
  opacity: 1;
}

@media (width <= 700px) {
  .user-lists {
    display: flex;
    flex-direction: column;
  }
  .user-list {
    width: 100%;
  }
}

.hovered-container {
  background-color: var(--mid);
  transition:
    background-color 0.3s,
    border 0.3s;
}

.change-display {
  justify-content: center;
  gap: 1.5rem;
}
.change-display h3 {
  margin: 0px;
}
.change-order-button {
  margin-inline: auto;
  width: 9rem !important;
  outline: none;
  border: none;
}
.change-order-button svg {
  height: 6rem;
  width: 4rem;
  pointer-events: none;
}
.change-order-button:hover {
  margin-inline: auto;
}

.change-order-popup {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.change-order-card {
  border-radius: 5px;
  padding: 35px 0px;
  background-color: var(--background);
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.db-change-order-card {
  border-radius: 2px;
  padding-block: 1.8rem;
  background-color: var(--dark);
  text-align: left;
  display: flex;
  flex-direction: column;
  color: var(--less-white);
  border: 2px solid var(--fancy-border);
  gap: 2rem;
  width: 100%;
  z-index: 3;
}
.db-change-order-card p {
  opacity: 0.7;
}
.db-change-order-card .coName {
  color: var(--white) !important;
  filter: brightness(2);
}
.db-change-order-card strong {
  color: var(--white) !important;
  filter: brightness(2);
}

.budget-totals h3 {
  margin: 0;
  filter: brightness(5);
}
.change-orders {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 1rem;
  width: 100%;
}
.open-item-change-orders {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 95%;
  gap: 10px;
}

.open-item-change-orders .db-change-order-card {
  width: 100%;
}
.change-order-top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 30px;
}
.change-order-top-row svg {
  height: 3rem;
  width: 3rem;
}

.change-order-title {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.p {
  font-weight: 500;
  font-size: 1rem;
  opacity: 0.7;
}

.change-order-open {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--mid);
  margin-inline: 60px;
  border-radius: 3px;
  box-sizing: border-box;
}

.change-order-open:hover .change-order-open-header {
  background-color: var(--dark);
  filter: brightness(1.5);
}

.change-order-open-true {
  background-color: var(--dark);
  filter: brightness(1.5);
}
.change-order-open-header {
  width: 100%;
  padding: 35px 35px;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.change-order-open-bottom {
  padding: 0px 35px;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.change-order-amounts {
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr;
  justify-content: space-between;
  align-items: center;
  background-color: var(--dark);
}
.row-objects {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-block: 1rem;
}
.rowObj {
  display: flex;
  flex-direction: column;
  gap: 0px;
}
.rowObj h3 {
  margin: 0;
  filter: brightness(5);
}

.row-amounts {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-block: 10px;
}

.header-amounts strong {
  text-align: right;
  font-size: larger;
}
.new-change-order-header {
  width: 95%;
  margin-inline: auto;
  box-sizing: border-box;
}

.new-change-order-header:hover .change-order-open-header {
  filter: none !important;
  background: none !important;
  cursor: unset;
}
.new-change-order-header .header-amounts {
  gap: 4rem !important;
  padding-inline: 4rem;
}
.bottom-amounts {
  display: flex;
  flex-direction: row;
  padding-block: 0px;
  background: none;
  text-align: right;
  padding-inline: 30px;
  gap: 1rem;
}
.change-order-header-amount {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 10px;
  user-select: none;
}
.change-order-amount {
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: right;
}

.co-buttons {
  display: flex;
  flex-direction: column;
  gap: 5px;
  z-index: 2;
}
.co-button {
  background: var(--dark);
  border: none;
  width: 3rem;
  height: 3rem;
  padding: 5px 8px;
  padding-top: 6px;
  border-radius: 2px;
  border: 2px solid var(--fancy-border);
}
.co-button:hover {
  cursor: pointer;
  filter: brightness(var(--filter3));
}
.input-group {
  display: flex;
  flex-direction: row;
  text-align: left;
  gap: 15px;
}

.co-input-group {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 10px;
  position: relative;
}

.new-co-input {
  background-color: var(--background);
  border: 1px solid var(--background);
  border-radius: 5px;
  padding: 15px 30px;
  color: var(--white);
  font-size: 16px;
  flex-grow: 1;
  cursor: pointer;
}

.new-co-input:focus {
  outline: none !important;
}

.active-co-input {
  background-color: var(--primary);
}

@keyframes shake {
  0% {
    margin-left: 0rem;
  }
  20% {
    margin-left: 0.1rem;
  }
  40% {
    margin-left: -0.1rem;
  }
  60% {
    margin-left: 0.05rem;
  }
  80% {
    margin-left: -0.05rem;
  }
  100% {
    margin-left: 0rem;
  }
}

.input-error-tag {
  animation: shake 0.5s ease-in-out 0s;
  color: var(--red);
}

.input-error {
  animation: shake 0.5s ease-in-out 0s;
}

.input-error:focus {
  border: 1px solid var(--red);
}

.notsingle-changeorder {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
}

.notsingle-changeorder-amnt {
  background-color: var(--mid);
  padding: 25px 15px;
  border-radius: 5px;
  width: fit-content;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.notsingle-changeorder-num {
  font-size: 3rem;
  font-weight: 600;
}

.notsingle-changeorder-pending {
  background-color: var(--mid);
  width: fit-content;
  margin: auto;
  padding: 5px 15px;
  border-radius: 5px;
}

.btn-disabled {
  cursor: unset;
  pointer-events: none;
}

.btn-disabled:hover {
  filter: none !important;
}

.user-page h1 {
  margin-bottom: 1rem;
}
.tab-button {
  font-size: 1.5rem;
  background: var(--background);
  border: none;
  color: var(--white);
  opacity: 0.3;
  font-weight: 600;
  padding-block: 0px;
}

.tab-button:hover {
  opacity: 0.6;
}
.active-tab-button {
  opacity: 1;
}
.active-tab-button:hover {
  opacity: 1;
}

.sub-tab-button {
  font-size: 1rem;
  background: var(--background);
  border: none;
  color: var(--white);
  opacity: 0.3;
  font-weight: 600;
  padding-block: 0px;
}
.sub-tab-button:hover {
  opacity: 0.6;
}
.sub-active-tab-button {
  opacity: 1;
}
.sub-active-tab-button:hover {
  opacity: 1;
}

.group-header {
  font-size: 1.5rem;
  color: var(--white);
  position: sticky;
  top: -10px;
  background-color: var(--background);
  z-index: 10;
  text-align: left;
  font-weight: 600;
  padding-top: 30px;
  padding-bottom: 20px;
  padding-inline: 2px;
}

.swal-container {
  background-color: var(--dark);
}
.swal-confirm {
  background-color: var(--mid);
}
.swal-input {
  resize: none;
  border: 1px solid var(--fancy-border);
  color: var(--less-white);
  font-size: unset;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.swal-input:focus {
  outline: none !important;
  box-shadow: none;
  border: 1px solid var(--less-white);
}

.swal-title {
  color: var(--white);
}

.open-ytd-h2 {
  margin: 0;
  margin-top: -30px;
}
.ytd-row {
  display: flex;
  padding-top: 20px;
  width: 100%;
  justify-content: center;
}
.ytd-item {
  display: flex;
  flex-direction: column;
  width: clamp(300px, 30%, 600px);
  gap: 10px;
}

.ytd-item h3 {
  margin: 0;
}

.ytd-item .ytd-single {
  flex: unset;
}

.ytd-span {
  padding-top: 25px;
  font-size: 32px;
  align-self: center;
}

.divising-line {
  width: 100%;
  margin-inline: auto;
  margin-bottom: 10px;
  height: 1px;
  background-color: var(--less-white);
}
.strong {
  font-weight: 700 !important;
}

.report-icon svg {
  height: 70px;
  width: 70px;
  z-index: 0;
  opacity: 1;
}

.run-container {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  width: 95%;
  padding-bottom: 20px;
}

.run-item {
  background-color: var(--dark);
  border: 2px solid var(--fancy-border);
  height: 150px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 2rem;
  padding-right: 2rem;
  position: relative;
  box-sizing: border-box;
  gap: 20px;
  width: 75%;
}

.run-item h2 {
  margin: 0px;
}

.run-item:hover .x-button {
  transition: all 0.2s ease-in-out;
  opacity: 0.2;
}
.run-left {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 5px;
}

.run-right {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 5px;
}

.user-page h2 {
  color: var(--white);
}

.cost-item {
  background-color: var(--dark);
  border: 2px solid var(--fancy-border);
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: fit-content;
  cursor: pointer;
  z-index: 3;
}

.disc-title {
  filter: brightness(var(--filter3));
}

.disc-item {
  cursor: unset;
  height: fit-content;
}
.discrepancy-body {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-content: flex-start;
}
.discrepancy-body-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.discrepancy-body-right {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.discrepancy-body-left svg {
  width: 30px;
  height: 30px;
}

.disc-bodies {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 50px;
  width: 100%;
}
.body-items {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.cost-body {
  padding-left: 4rem;
  padding-right: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 120px;
  padding-block: 2rem;
}
.disc-body {
  padding-block: 2.5rem;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  align-items: flex-start;
  height: fit-content;
}

.disc-body h2 {
  margin-bottom: 10px;
}
.cost-items {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 85%;
}

.cost-left {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  height: 100%;
  gap: 10px;
}

.cost-left h3,
h2 {
  color: var(--white);
  margin: 0px;
  padding: 0px;
}

.cost-item .home-widget-title {
  opacity: 0.7;
  text-align: left;
}

.hwt-strong {
  font-weight: 600;
  opacity: 1 !important;
}

.cost-right {
  display: flex;
  flex-direction: column;
  text-align: right;
  justify-content: space-between;
  height: 100%;
  gap: 5px;
}

.cost-bottom-right {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.cost-bottom {
  border-top: 1px solid var(--fancy-border);
  display: flex;
  flex-direction: row;
  gap: 5.5rem;
  padding-left: 4rem;
  padding-right: 2rem;
  align-items: center;
}
.feedback-card {
  padding-inline: 4rem;
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.cost-bottom-item {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 5px;
}
.copy-btn {
  cursor: pointer;
}

.copy-btn:hover {
  text-decoration: underline;
}

.cost-item * {
  user-select: none;
}
.co-job {
  background: none;
  border: none;
  color: var(--white);
}

.invoice-totals {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 220px;
  justify-content: space-between;
}

.invoice-body {
  padding-right: 4rem;
}

.App {
  min-height: 100vh;
}

.footer {
  position: relative;
  bottom: 0;
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: var(--dark);
  color: var(--less-white);
  border-top: 2px solid var(--fancy-border);
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

.submission-form-button {
  justify-self: flex-end;
  background: var(--dark);
  border: none;
  outline: none;
  color: var(--less-white);
  font-weight: 500;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  height: fit-content;
  padding: 5px 15 px;
}

.submission-form-button:hover {
  cursor: pointer;
  filter: brightness(var(--filter3));
}

.new-widget-top h2 {
  padding-top: 20px;
}

.feedback-top {
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: -20px;
}
.feedback-input-group {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 8px;
  padding: 10px;
}

.feedback-button {
  background-color: var(--mid);
  border-radius: 2px;
  outline: none;
  border: none;
  padding: 12px 25px;
  width: 160px;
  color: var(--white);
  font-size: 16px;
}
.feedback-textarea {
  background-color: var(--mid);
  color: var(--white);
  border: 2px solid var(--mid);
  outline: none;
  border-radius: 2px;
  resize: none;
  line-height: 16px;
  height: calc(16px * 6);
  padding: 15px 20px;
  font-size: 16px;
  font-family: unset;
}

.feedback-textarea:focus {
  border: 2px solid var(--primary);
}

.feedback-active {
  background-color: var(--primary);
}
